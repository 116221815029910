import React from 'react';

// Pieces imports
import { Section, Heading } from '@thepuzzlers/pieces';

// Local components
import { NumberedList } from 'components';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Faq = ({ data: { headline, list } }) => {
  return (
    <Section>
      <Heading
        sx={{
          gridColumn: [
            '1 / span 12',
            '1 / span 9',
            '2 / span 18',
            '1 / span 14',
            '2 / span 15',
            '3 / span 14'
          ],
          lineHeight: 1.35,
          marginBottom: [
            '7rem',
            '8.7rem',
            '10.4rem',
            '7.1rem',
            '7.9rem',
            '10.1rem'
          ],
          fontFamily: 'primary.normal',
          lineHeight: 1.25,
          '& > span': {
            fontFamily: 'primary.bold'
          },
          fontSize: ['3rem', '3.8rem', '4.4rem', '2.8rem', '4.2rem', '4.8rem']
        }}
        as="h2"
        dangerouslySetInnerHTML={{ __html: convertApiHtmlText(headline.html) }}
      />
      <NumberedList data={list} />
    </Section>
  );
};
