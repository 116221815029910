import { useStaticQuery, graphql } from 'gatsby';

export const useNachhaltigkeitPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: nachhaltigkeitPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          image {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        comparison {
          charts {
            color
          }
        }
        cycle {
          images {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
      }
    }
  `);
  return data;
};
