import React from 'react';
import {
  Section,
  Heading,
  Box,
  GridWrapper,
  Paragraph
} from '@thepuzzlers/pieces';
import woodPattern from 'assets/patterns/wooden-pattern-mdl-haus-deutschland.jpg';
import { checkIsLastData } from 'utils';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Comparison = ({
  staticData: { charts: staticChartData },
  data: { charts, headline }
}) => {
  const renderCharts = (charts) => {
    return charts.map((chart, i, arr) => (
      <Chart
        key={`chart-${i}`}
        index={i}
        totalData={arr.length}
        data={chart}
        color={staticChartData[i].color}
      />
    ));
  };

  const bgSectionEnabled = ({ colors }) =>
    `url(${woodPattern}), ${colors.primary}`;
  const bgSectionDisabled = 'none, transparent';
  return (
    <Section
      sx={{
        background: [
          bgSectionEnabled,
          null,
          bgSectionDisabled,
          bgSectionEnabled,
          bgSectionDisabled,
          null
        ] // full width
      }}>
      <GridWrapper
        variant="inside.autoColumns"
        sx={{
          background: [
            null,
            null,
            bgSectionEnabled,
            bgSectionDisabled,
            bgSectionEnabled,
            null
          ], // grid width only
          gridColumn: [
            '1 / span 12',
            null,
            '1 / span 24',
            '1 / span 24',
            '1 / span 24',
            '1 / span 24'
          ],
          padding: [
            '13.5rem 0 13.6rem 0',
            '17.7rem 0 17.7rem 0',
            '16.9rem 0 16.9rem 0',
            '11.0rem 0 10.9rem 0',
            '11.7rem 0 11.7rem 0',
            '10.7rem 0 11.8rem 0'
          ]
        }}>
        <Heading
          sx={{
            gridColumn: [
              '1/ span 12',
              '2 / span 10',
              '4 / span 14',
              '3 / span 14',
              '4 / span 10',
              '3 / span 9'
            ],
            gridRow: [null, null, null, null, null, '1 / span 1'],
            marginBottom: ['6.4rem', '8rem', null, '6.4rem', '10rem', 0],
            color: 'white',
            fontFamily: 'primary.normal',
            lineHeight: 1.5,
            '& > span': {
              fontFamily: 'primary.bold',
              color: 'secondary'
            },
            fontSize: [
              '1.9rem',
              '2.2rem',
              '2.2rem',
              '1.9rem',
              '2.2rem',
              '2.4rem'
            ]
          }}
          as="h2"
          dangerouslySetInnerHTML={{
            __html: convertApiHtmlText(headline.html)
          }}
        />
        <GridWrapper // CHART CONTAINER
          className="chart-container"
          variant="inside.autoColumns"
          sx={{
            gridColumn: [
              '1 / span 12',
              '2 / span 10',
              '4 / span 18',
              '9 / span 14',
              '9 / span 13',
              '13 / span 10'
            ],
            gridRow: [null, null, null, null, null, '2 / span 1']
          }}>
          {renderCharts(charts)}
        </GridWrapper>
      </GridWrapper>
    </Section>
  );
};

//========== COMPONENTS FOR COMPARISON SECTION ===========
const Chart = ({
  data: { title, percentage, value },
  // title,
  // percentageValue,
  // valueText,
  color,
  index,
  totalData
}) => {
  const isLastData = checkIsLastData(index, totalData);
  return (
    <GridWrapper
      className="chart-container"
      variant="inside.autoColumns"
      sx={{
        gridColumn: [
          '1 / span 12',
          '1 / span 10',
          '1 / span 18',
          '1 / span 14',
          '1 / span 13',
          '1 / span 10'
        ],
        margin: [
          !isLastData ? '0 0 4rem 0' : 0,
          null,
          !isLastData ? '0 0 4.8rem 0' : 0,
          !isLastData ? '0 0 3.2rem 0' : 0,
          !isLastData ? '0 0 4rem 0' : 0,
          null
        ]
      }}>
      {/** PROGRESS CHART CONTAINER */}
      <Box
        className="progress-container"
        sx={{
          gridColumn: [
            '1 / span 12',
            '1 / span 10',
            '1 / span 18',
            '1 / span 14',
            '1 / span 13',
            '1 / span 10'
          ]
        }}>
        <Box
          className="progress-bar"
          sx={{
            height: ['1rem', '1.2rem', null, '1rem', '1.2rem', '1.2rem'],
            margin: ['0 0 1.2rem 0', null, null, null, null, null],
            backgroundColor: color,
            width: percentage
          }}
        />
      </Box>

      {/** TEXT CONTAINER */}
      <GridWrapper
        className="text-container"
        variant="inside.autoColumns"
        sx={{
          gridColumn: [
            '1 / span 12',
            '1 / span 10',
            '1 / span 18',
            '1 / span 14',
            '1 / span 13',
            '1 / span 10'
          ]
        }}>
        <Paragraph
          sx={{
            color: 'white',
            gridColumn: [
              '1 / span 8',
              '1 / span 6',
              '1 / span 10',
              '1 / span 8',
              '1 / span 7',
              '1 / span 5'
            ],
            gridRow: ['1 / span 1', null, null, null, null, null],
            lineHeight: 1.25,
            fontFamily: 'body.bold',
            fontSize: [
              '1.6rem',
              '1.8rem',
              '1.8rem',
              '1.6rem',
              '1.8rem',
              '1.8rem'
            ]
          }}>
          {title}
        </Paragraph>
        <Paragraph
          sx={{
            color,
            gridColumn: [
              '9 / span 4',
              '8 / span 3',
              '15 / span 4',
              '11 / span 4',
              '11 / span 3',
              '8 / span 3'
            ],
            gridRow: ['1 / span 1', null, null, null, null, null],
            textAlign: 'right',
            lineHeight: 1,
            fontFamily: 'body.bold',
            fontSize: [
              '1.6rem',
              '1.8rem',
              '1.8rem',
              '1.6rem',
              '1.8rem',
              '1.8rem'
            ]
          }}>{`${value} ${percentage}`}</Paragraph>
      </GridWrapper>
    </GridWrapper>
  );
};
