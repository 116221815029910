import React from 'react';
import { Section, Heading } from '@thepuzzlers/pieces';

// Local components
import { ImageWithSimpleList } from 'components';
import { convertApiHtmlText } from 'graphqlHooks/helper';

const styles = [
  {
    wrapperSx: {
      gridColumn: [
        '1 / span 12',
        '1 / span 10',
        '3 / span 15',
        '2 / span 23',
        '1 / span 23',
        '2 / span 20'
      ]
    },
    textSx: {
      gridColumn: [
        '1 / span 11',
        '1 / span 8',
        '1 / span 13',
        '13 / span 11',
        '13 / span 9',
        '12 / span 7'
      ]
    }
  },
  {
    wrapperSx: {
      gridColumn: [
        '1 / span 11',
        '1 / span 10',
        '8 / span 15',
        '4 / span 21',
        '3 / span 20',
        '3 / span 19'
      ]
    },
    textSx: {
      gridColumn: [
        '1 / span 11',
        '1 / span 8',
        '1 / span 13',
        '1 / span 11',
        '1 / span 9',
        '3 / span 8'
      ]
    }
  },
  {
    wrapperSx: {
      gridColumn: [
        '1 / span 11',
        '1 / span 10',
        '4 / span 15',
        '1 / span 22',
        '2 / span 21',
        '3 / span 17'
      ]
    },
    textSx: {
      gridColumn: [
        '1 / span 11',
        '1 / span 9',
        '1 / span 13',
        '12 / span 11',
        '11 / span 9',
        '11 / span 7'
      ]
    }
  }
];

export const Cycle = ({
  staticData: { images: staticImageData },
  data: { headline, images }
}) => (
  <Section>
    <Heading
      sx={{
        gridColumn: [
          '1 / span 12',
          null,
          '1 / span 20',
          '1 / span 16',
          '1 / span 17',
          '2 / span 15'
        ],
        marginBottom: ['5.9rem', '23rem', '14rem', '6.8rem', '10rem', '7.6rem'],
        fontFamily: 'primary.normal',
        lineHeight: 1.25,
        '& > span': {
          fontFamily: 'primary.bold'
        },
        fontSize: ['3rem', '3.8rem', '4.4rem', '2.8rem', '4.2rem', '4.8rem']
      }}
      as="h2"
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(headline.html) }}
    />
    {images.map((data, i) => {
      const { text_one, text_two, text_three } = data;

      // make description list to be an array to match with the required props
      const descriptionList = [
        {
          text: text_one
        },
        {
          text: text_two
        },
        {
          text: text_three
        }
      ];

      return (
        <ImageWithSimpleList
          key={data.title}
          wrapperSx={styles[i].wrapperSx}
          textSx={styles[i].textSx}
          data={{
            descriptionList
          }}
          image={{
            image: staticImageData[i].src,
            alt: staticImageData[i].alt,
            title: data.title
          }}
          index={i}
          totalData={images.length}
        />
      );
    })}
  </Section>
);
