import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';

// Local Components
import { Spacer } from 'components';
import { HeaderWithNumber } from 'components/section';

// Self Components
import {
  useNachhaltigkeitPageData,
  Comparison,
  Cycle,
  Faq
} from 'sections/nachhaltigkeit';

// Assets
import thumbnail from 'assets/seo/thumbnail.png';
import { useKisNachhaltigkeitPageData } from 'graphqlHooks';

const Leistungen = () => {
  const { seo, cycle, header, comparison } = useNachhaltigkeitPageData();

  const {
    comparison: cmsComparison,
    cycle: cmsCycle,
    faq: cmsFaq,
    header: cmsHeader
  } = useKisNachhaltigkeitPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <HeaderWithNumber staticData={header} data={cmsHeader} />
      <Spacer height={['8rem', '16rem', '24rem', '16rem', '24rem', '30rem']} />
      <Comparison staticData={comparison} data={cmsComparison} />
      <Spacer height={['24rem', '24rem', '24rem', '16rem', '24rem', '24rem']} />
      <Cycle staticData={cycle} data={cmsCycle} />
      <Spacer height={['24rem', '40rem', '40rem', '16rem', '40rem', '30rem']} />
      <Faq data={cmsFaq} />
      <Spacer height={['16rem', '24rem', '24rem', '16rem', '24rem', '24rem']} />
    </>
  );
};

export default injectIntl(Leistungen);
